var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("main", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "group mb-5" }, [
          _c("div", { staticClass: "steps mb-4 mb-lg-0" }, [
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", { staticClass: "completed" }, [_vm._v(" Brand ")]),
              _c(
                "li",
                {
                  staticClass: "active",
                  class: { completed: _vm.isCategorySelected }
                },
                [_vm._v("Category")]
              ),
              _c("li", [_vm._v("Sub-Category")]),
              _c("li", [_vm._v("View & Compare")])
            ])
          ])
        ]),
        _c("header", { staticClass: "content-header" }, [
          _c("h1", { staticClass: "text-primary theme-text" }, [
            _vm._v("Select Category")
          ]),
          _c("p", { staticClass: "lead" }, [
            _vm._v("What are you looking for?")
          ])
        ]),
        _c("div", { staticClass: "content-body" }, [
          _c(
            "div",
            { staticClass: "cards four-column-card d-flex" },
            _vm._l(_vm.categories, function(category) {
              return _c(
                "div",
                {
                  key: category.id,
                  staticClass: "card",
                  class: {
                    active:
                      _vm.isCategorySelected &&
                      _vm.selectedCategory.id == category.id
                  },
                  attrs: {
                    id: _vm.sanitizeInput(category.name) + "-category-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectCategory(category)
                    }
                  }
                },
                [
                  _c("figure", [
                    _c("img", {
                      attrs: { src: category.icon, alt: category.name }
                    }),
                    _c("figcaption", [_vm._v(_vm._s(category.name))])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }